import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header, Footer, TRProf, PageHeader } from "../../components";
import { Link } from "react-router-dom";

function GroupAdd() {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    exerPoints: [],
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const arrayChange = (event) => {
    const target = event.target;
    const [name, index] = event.target.name.split("-");
    const value = target.type === "checkbox" ? target.checked : target.value;
    setInputs((values) => {
      const prop = values[name];
      prop[index] = value;
      return { ...values, [name]: prop };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(process.env.REACT_APP_API_ENDPOINT + "prof/group", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(inputs),
    })
      .then((response) => response.json())
      .then((r) => {
        if (r.success) {
          navigate("/prof", { state: { groups: r.data } });
        }
      });
  };

  return (
    <div>
      <Header />
      <section className="flexSec">
        <PageHeader title="New Group" description="Fill the table and submit" />
      </section>
      <section className="newSec">
        <form
          className=""
          onSubmit={handleSubmit}
          onKeyDown={(e) =>
            (e.key === "Enter" ||
              e.key === "ArrowUp" ||
              e.key === "ArrowDown") &&
            e.preventDefault()
          }
          onWheel={(e) => e.target.blur()}
        >
          <table className="table textWhite">
            <tbody>
              <TRProf
                title="Program (Text: e.g. MIB)"
                type="text"
                name="program"
                value={inputs.program}
                handleChange={handleChange}
                placeholder="Program"
                required={true}
              />
              <TRProf
                title="Academic Year (Number: e.g. 2223)"
                type="number"
                name="year"
                value={inputs.year}
                handleChange={handleChange}
                placeholder="Year"
                required={true}
              />
              <TRProf
                title="Semester (Number: 1 or 2)"
                type="number"
                name="semester"
                value={inputs.semester}
                handleChange={handleChange}
                placeholder="Semester"
                required={true}
              />
              <TRProf
                title="Campus (L or P)"
                type="text"
                name="campus"
                value={inputs.campus}
                handleChange={handleChange}
                placeholder="Campus"
                required={true}
              />
              <TRProf
                title="Index (Optional)"
                type="number"
                name="index"
                value={inputs.index}
                handleChange={handleChange}
                placeholder="Index"
                required={false}
              />
              <tr>
                <th scope="row">{"Group Name (Readonly):"}</th>
                <td>
                  <input
                    value={
                      (typeof inputs.program !== "undefined" &&
                      inputs.program !== ""
                        ? inputs.program
                        : "") +
                      (typeof inputs.year !== "undefined" && inputs.year !== ""
                        ? "-" + inputs.year
                        : "") +
                      (typeof inputs.semester !== "undefined" &&
                      inputs.semester !== ""
                        ? "-S" + inputs.semester
                        : "") +
                      (typeof inputs.campus !== "undefined" &&
                      inputs.campus !== ""
                        ? "-" + inputs.campus
                        : "") +
                      (typeof inputs.index !== "undefined" &&
                      inputs.index !== ""
                        ? "-" + inputs.index
                        : "")
                    }
                    className="form-control"
                    placeholder="Name"
                    readOnly={true}
                  />
                </td>
              </tr>
              <TRProf
                title="CPM Points (Out of 20)"
                type="number"
                name="exerPoints-0"
                value={inputs.exerPoints[0]}
                handleChange={arrayChange}
                placeholder="CPM Points"
                required={true}
              />
              <TRProf
                title="RL Points (Out of 20)"
                type="number"
                name="exerPoints-1"
                value={inputs.exerPoints[1]}
                handleChange={arrayChange}
                placeholder="RL Points"
                required={true}
              />
              <TRProf
                title="PERT Points (Out of 20)"
                type="number"
                name="exerPoints-2"
                value={inputs.exerPoints[2]}
                handleChange={arrayChange}
                placeholder="PERT Points"
                required={true}
              />
              <TRProf
                title="MSP Points (Out of 20)"
                type="number"
                name="exerPoints-3"
                value={inputs.exerPoints[3]}
                handleChange={arrayChange}
                placeholder="MSP Points"
                required={true}
              />
            </tbody>
          </table>

          <Link to="/prof" className="btn btn-lg btn-outline-light mt-5 mx-5">
            Cancel
          </Link>

          <input
            className="btn btn-lg btn-outline-success mt-5"
            type="submit"
          />
        </form>
      </section>
      <Footer />
    </div>
  );
}

export default GroupAdd;
