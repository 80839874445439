function ProjectDropdown({ links, customClasses, newTab, btnName }) {
  return (
    <div className="mb-2">
      {typeof links !== "undefined" && (
        <div className="dropdown">
          <button
            className={"btn dropdown-toggle btnWide btnSmall " + customClasses}
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {btnName}
          </button>
          <ul className="dropdown-menu dropdown-menu-dark">
            {links.map((link, index) => {
              return (
                <li key={"droplink" + index + link.lName}>
                  <a
                    className={"dropdown-item btn btnSmall "}
                    href={link.lUrl}
                    target={newTab}
                  >
                    {link.lName}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

export default ProjectDropdown;
