const AboutCard = ({ teacher }) => {
  return (
    <div className="card aboutCard p-2">
      <img
        src={teacher.image}
        className="card-img-top card-img img-circle imgUnselectable div-align-center mt-4"
        alt={teacher.fname + " " + teacher.lname}
      />
      <div className="card-body mt-3">
        <h5 className="card-title text-align-left">
          {teacher.fname + " " + teacher.mname + " " + teacher.lname}
        </h5>
        <p className="card-text text-align-left">
          {teacher.affiliation +
            " at " +
            teacher.school +
            ". " +
            teacher.fname +
            " " +
            teacher.education +
            "."}
        </p>
        <a href={teacher.link} className="btn btn-success mt-4" target="blank">
          Personal Site
        </a>
      </div>
    </div>
  );
};

export default AboutCard;
