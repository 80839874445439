function TRProf(props) {
  return (
    <tr>
      <th scope="row">{props.title + ":"}</th>
      <td>
        <input
          type={props.type}
          name={props.name}
          value={props.value || ""}
          onChange={props.handleChange}
          className="form-control"
          placeholder={props.placeholder}
          required={props.required}
        />
      </td>
    </tr>
  );
}

export default TRProf;
