import { Link } from "react-router-dom";
import { Header, FeatureItem, Footer } from "../../components";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

function Home() {
  const [ref1, inView1] = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const [ref2, inView2] = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  return (
    <div>
      <Header />

      <section id="title-sec" className="title-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <motion.div
                ref={ref1}
                initial={{ x: "-10vw", opacity: 0 }}
                animate={
                  inView1 ? { x: 0, opacity: 1 } : { x: "-10vw", opacity: 0 }
                }
                transition={{ duration: 0.4, ease: "easeInOut" }}
              >
                <h1 className="title-head appTitle unselectable">
                  Project <br />
                  Management
                </h1>
                <h3 className="title-subhead unselectable">
                  Planning, Execution, Control
                </h3>
                <div className="btn-group title-btn-div">
                  <button
                    type="button"
                    className="btn btn-outline-light btn-lg btnGreen"
                  >
                    <i className="fa-solid fa-play"></i> Intro
                  </button>
                  <Link
                    to="/prof"
                    className="btn btn-outline-light btn-lg btnGreen"
                  >
                    <i className="fa-solid fa-user"></i> Teachers
                  </Link>
                  <Link
                    to="/content"
                    className="btn btn-outline-light btn-lg btnGreen"
                  >
                    <i className="fa-solid fa-right-to-bracket"></i> Students
                  </Link>
                </div>
              </motion.div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 title-img-div">
              <motion.div
                ref={ref1}
                initial={{ x: "10vw", opacity: 0 }}
                animate={
                  inView1 ? { x: 0, opacity: 1 } : { x: "10vw", opacity: 0 }
                }
                transition={{ duration: 0.4, ease: "easeInOut" }}
              >
                <img
                  className="imgUnselectable title-img"
                  src="images/pm2.png"
                  alt="project-management"
                />
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      <section id="features-sec" className="features-sec white-sec">
        <motion.div
          className="newSec"
          ref={ref2}
          initial={{ y: "-10vw", opacity: 0 }}
          animate={inView2 ? { y: 0, opacity: 1 } : { y: "-10vw", opacity: 0 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
        >
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 features-box">
              <FeatureItem
                icon="fa-brain"
                title="Challenging"
                text="Stimulate critical thinking and problem-solving to push the learners to their full potential"
              />
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 features-box">
              <FeatureItem
                icon="fa-comments"
                title="Engaging"
                text="Interest & motivate to learn via real-world examples & exercises, games and case studies"
              />
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 features-box">
              <FeatureItem
                icon="fa-bullseye"
                title="Relevant"
                text="Relate to the learners' goals and needs, and provide them with useful knowledge and skills"
              />
            </div>
          </div>
        </motion.div>
      </section>

      <section id="press-sec" className="pb-5 px-5 white-sec ">
        <a href="https://www.ieseg.fr/en/" target="blank">
          <img
            className="press-img mx-5 "
            src="images/ieseglogo.png"
            alt="IESEG"
          />
        </a>
        <a href="https://www.kuleuven.be/kuleuven/" target="blank">
          <img
            className="press-img "
            src="images/kuleuvenlogo.png"
            alt="kuleuven"
          />
        </a>
        {/* <a href="https://picsbelgium.be/" target="blank">
          <img
            className="press-img mx-5 "
            src="images/picslogo.png"
            alt="pics"
          />
        </a> */}
        <a href="https://www.proove.eu/" target="blank">
          <img
            className="press-img mx-5 "
            src="images/proovelogo.png"
            alt="proove"
          />
        </a>
      </section>

      <Footer />
    </div>
  );
}

export default Home;
