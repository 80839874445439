import projectData from "./contentData.json";
import { Footer, Header, PageHeader, Project } from "../../components";

const Content = () => {
  const ProjectList = () =>
    projectData.map((project, i) => (
      <Project
        key={i}
        oddEven={projectData.length % 2 === 1 ? 1 : 0}
        id={project.id}
        title={project.title}
        top={project.top}
        bottom={project.bottom}
        image={project.image}
        color={project.bgcolor}
        links1={project.links1}
        links2={project.links2}
        links3={project.links3}
        links4={project.links4}
        links5={project.links5}
        description={project.description}
      />
    ));

  return (
    <div>
      <Header />
      <section id="content-sec" className="projects">
        <PageHeader
          title="Course Content"
          description="Slides and exercises with instant grading"
        />
        <div>
          <div className="row">
            <ProjectList />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Content;
