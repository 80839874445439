import { Link, NavLink } from "react-router-dom";

function NavLinksLog(props) {
  return (
    <>
      <div className="dropdown dropdownHover">
        <button
          className="list nav-link dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {props.email}
        </button>
        <ul className="dropdown-menu dropdown-menu-dark">
          <li>
            <Link className="dropdown-item" onClick={() => props.logout(false)}>
              Logout
            </Link>
          </li>
        </ul>
      </div>
      {props.type === "user" && (
        <>
          <NavLink to="/user" onClick={() => props.setIsMenuOpen(false)}>
            Grades
          </NavLink>
          <NavLink to="/content" onClick={() => props.setIsMenuOpen(false)}>
            Content
          </NavLink>
          <span className="vertical-divider unselectable">|</span>
        </>
      )}
      {props.type === "prof" && (
        <>
          <NavLink
            to="/prof"
            onClick={() => props.setIsMenuOpen(false)}
            className={"borderedRight"}
          >
            Groups
          </NavLink>
          <span className="vertical-divider unselectable">|</span>
        </>
      )}
    </>
  );
}

export default NavLinksLog;
