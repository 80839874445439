import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Header,
  Footer,
  FullscreenLoading,
  WarnSubSec,
} from "../../components";
import { CpmVis, RlVis, PertVis, MspVis } from "../../routes";

function DetailedUser(props) {
  const { id } = useParams();

  const [data, setData] = useState(null);

  const location = useLocation();

  useEffect(() => {
    if (location.state?.data) {
      setData(location.state.data);
    }
  }, [location.state?.data]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}prof/user/${id}`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((r) => {
        if (r.success) {
          setData(r.data);
        } else {
          console.log(r.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  // When the user clicks on the top button, scroll to the top of the document
  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  if (data) {
    return (
      <div>
        <Header />

        <WarnSubSec
          title={data.firstName + " " + data.lastName}
          subtitle={data.groupName}
          header={true}
        />

        <div className="dropup">
          <button
            className="btn btn-lg btn-success dropdown-toggle"
            type="button"
            id="scrollBtn"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Go to
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button
                className="dropdown-item"
                onClick={topFunction}
                title="Go to top"
              >
                Top
              </button>
            </li>
            <li>
              <a className="dropdown-item" href="#sec-CPM">
                CPM
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#sec-RL">
                RL
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#sec-PERT">
                PERT
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#sec-MSP">
                MSP
              </a>
            </li>
          </ul>
        </div>

        <section id="sec-CPM">
          {data.subState[0] === true ? (
            <CpmVis
              proj={data.proj}
              keys={data.keys[0]}
              params={data.params}
              subState={data.subState[0]}
              inputs={data.inputs[0]}
              grade={data.grades[0]}
            />
          ) : (
            <WarnSubSec title={"CPM Not Submitted"} header={false} />
          )}
        </section>

        <section id="sec-RL" className="big-mt">
          {data.subState[1] === true ? (
            <RlVis
              proj={data.proj}
              keys={data.keys[1]}
              params={data.params}
              subState={data.subState[1]}
              inputs={data.inputs[1]}
              grade={data.grades[1]}
            />
          ) : (
            <WarnSubSec title={"RL Not Submitted"} header={false} />
          )}
        </section>

        <section id="sec-PERT" className="big-mt">
          {data.subState[2] === true ? (
            <PertVis
              proj={data.proj}
              keys={data.keys[2]}
              params={data.params}
              subState={data.subState[2]}
              inputs={data.inputs[2]}
              grade={data.grades[2]}
            />
          ) : (
            <WarnSubSec title={"PERT Not Submitted"} header={false} />
          )}
        </section>

        <section id="sec-MSP" className="big-mt">
          {data.subState[3] === true ? (
            <MspVis
              proj={data.proj}
              keys={data.keys[3]}
              params={data.params}
              subState={data.subState[3]}
              inputs={data.inputs[3]}
              grade={data.grades[3]}
            />
          ) : (
            <WarnSubSec title={"MPS Not Submitted"} header={false} />
          )}
        </section>

        <Footer />
      </div>
    );
  } else {
    return <FullscreenLoading />;
  }
}

export default DetailedUser;
