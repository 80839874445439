import { useEffect, useState } from "react";
import {
  Header,
  Footer,
  Q1,
  SubmitModal,
  FullscreenLoading,
  PageHeader,
} from "../../components";
import { reInt } from "../../util/regExpModels";

function RlQuest(props) {
  const [data, setServerData] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "user/proj", {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => setServerData(data));
  }, []);

  const [inputs, setInputs] = useState({
    prLvl: [],
    sgsSt: [],
    pgsSt: [],
  });

  useEffect(() => {
    if (data != null) {
      function inputResize(name, initialValue) {
        setInputs((values) => {
          let prop = values[name];
          prop.length = data.params.n;
          prop.fill(initialValue);
          return { ...values, [name]: prop };
        });
      }
      inputResize("prLvl", "");
      inputResize("sgsSt", "");
      inputResize("pgsSt", "");
    }
  }, [data]);

  const arrayChange = (event) => {
    const target = event.target;
    const [name, index] = event.target.name.split("-");
    let value = target.type === "checkbox" ? target.checked : target.value;
    if (target.type !== "checkbox") {
      if (!reInt.test(value.toString())) {
        return;
      }
    }
    setInputs((values) => {
      const prop = values[name];
      prop[index] = value;
      return { ...values, [name]: prop };
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    if (!reInt.test(value.toString())) {
      return;
    }
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "user/sub/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ inputs: inputs, index: props.exerNr }),
    })
      .then((response) => response.json())
      .then((r) => props.updateSeverData(r.data));

    event.preventDefault();
  };

  if (data != null) {
    return (
      <div>
        <Header />
        <section className="flexSec">
          <PageHeader
            title={"Resource Leveling"}
            description={"Project data for the RL exercise"}
          />
        </section>
        <section className="newSec">
          <table className="table textWhite">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Activity</th>
                <th scope="col">Duration</th>
                <th scope="col">Predecessors</th>
                <th scope="col">Resource</th>
              </tr>
            </thead>
            <tbody>
              {data.params.N.map((job, index) => {
                return (
                  <tr key={("cpmDataTable" + job + index).toString()}>
                    <th scope="row">{index + 1}</th>
                    <td>{job}</td>
                    <td>{data.proj.D[index]}</td>
                    <td>{data.proj.pStr[index]}</td>
                    <td>{data.params.rNames[data.proj.R[index]]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <table className="table textWhite big-mt">
            <thead>
              <tr>
                <th scope="col">Assigned Priority Rule:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  {data.params.prAbr[data.proj.prRuleNr] +
                    ": " +
                    data.params.prNames[data.proj.prRuleNr]}
                </th>
              </tr>
              <tr>
                <td>Tiebreaker* rule: Lowest Activity Index (#) First</td>
              </tr>
            </tbody>
          </table>
          <p>
            *Hint: Use the tiebreaker if the assigned priority rule results in a
            tie among two or more activities.
          </p>
        </section>

        <section className="newSec white-sec">
          <h2 className="secTitle">Resource Leveling Questions</h2>
          <div className="big-mt">
            <form
              autocomplete="off"
              onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
              onWheel={(e) => e.target.blur()}
            >
              <div className="text-align-left">
                <h5>
                  Question 1: Resource Leveling Calculations (18 points out of
                  20).
                </h5>
              </div>
              <table className="table  mt-4">
                <thead>
                  <tr>
                    <th scope="col">Activity</th>
                    <th scope="col">Priority Level (6/18)</th>
                    <th scope="col">SGS Starting Time (6/18)</th>
                    <th scope="col">PGS Starting Time (6/18)</th>
                  </tr>
                </thead>
                <tbody>
                  {data.params.N.map((job, index) => {
                    return (
                      <tr key={("rlInputTR" + job + index).toString()}>
                        <th scope="row">{index + 1 + ". " + job}</th>
                        <td>
                          <input
                            type="text"
                            name={`prLvl-${index}`}
                            value={inputs.prLvl[index] || ""}
                            onChange={arrayChange}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name={`sgsSt-${index}`}
                            value={inputs.sgsSt[index] || ""}
                            onChange={arrayChange}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name={`pgsSt-${index}`}
                            value={inputs.pgsSt[index] || ""}
                            onChange={arrayChange}
                            className="form-control"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Q1
                classes="big-mt"
                qNr={2}
                question="What is the total duration of the project if planned with Serial Generation Scheme? (1 point out of 20)"
                name="sgsMakespan"
                value={inputs["sgsMakespan"]}
                handleFunction={handleChange}
                placeholder="SGS Project Duration"
              />

              <Q1
                classes="big-mt"
                qNr={3}
                question="What is the total duration of the project if planned with Parallel Generation Scheme? (1 point out of 20)"
                name="pgsMakespan"
                value={inputs["pgsMakespan"]}
                handleFunction={handleChange}
                placeholder="PGS Project Duration"
              />

              <div className="big-mt">
                <SubmitModal
                  btnText="Submit"
                  classes=""
                  variant="success"
                  title="Ready to submit your solutions?"
                  details="The submission is irreversivble. You only have one attempt."
                  handleSubmit={handleSubmit}
                />
              </div>
            </form>
          </div>
        </section>
        <Footer />
      </div>
    );
  } else {
    return <FullscreenLoading />;
  }
}

export default RlQuest;
