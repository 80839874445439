import { useContext, useState } from "react";
import { useLogout } from "../../hooks/auth";

import { Link } from "react-router-dom";
import { NavLinksHome, NavLinksLog } from "../Header";
import { UserContext } from "../../context/userContext";

import openMenu from "../../images/open.svg";
import closeMenu from "../../images/close.svg";

function Header() {
  const [userContext] = useContext(UserContext);
  const logout = useLogout();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <header className="header">
        <Link to="/">
          <img
            className="brand-img img-round-corners"
            src={`${process.env.PUBLIC_URL}/images/pmlogowhite.png`}
            alt="Projet Management"
          />
        </Link>
        <div className="">
          <button
            className="hidden-dropdown-toggle"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <img className="closeMenu" src={closeMenu} alt="Close" />
            ) : (
              <img className="openMenu" src={openMenu} alt="Open" />
            )}
          </button>
          <nav className={`links ${isMenuOpen ? "open" : "closed"}`}>
            {userContext.loggedIn && (
              <NavLinksLog
                email={userContext.data.email}
                type={userContext.data.type}
                logout={logout}
                setIsMenuOpen={setIsMenuOpen}
              />
            )}
            <NavLinksHome setIsMenuOpen={setIsMenuOpen} />
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;
