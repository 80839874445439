import { PageHeader, ResultTD } from "../../components";

function round2Digits(x) {
  return Math.round((x + Number.EPSILON) * 100) / 100;
}

function PertVis(props) {
  return (
    <div>
      <section className="flexSec">
        <PageHeader
          title={"PERT Method"}
          description={"Project data for the PERT exercise"}
        />
      </section>
      <section className="newSec ">
        <table className="table textWhite">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Activity</th>
              <th scope="col">Duration</th>
              <th scope="col">Predecessors</th>
              <th scope="col">Resource</th>
            </tr>
          </thead>
          <tbody>
            {props.params.N.map((job, index) => {
              return (
                <tr key={("cpmDataTable" + job + index).toString()}>
                  <th scope="row">{index + 1}</th>
                  <td>{job}</td>
                  <td>{props.proj.D[index]}</td>
                  <td>{props.proj.pStr[index]}</td>
                  <td>{props.params.rNames[props.proj.R[index]]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <table className="table textWhite big-mt">
          <thead>
            <tr>
              <th scope="col">Assigned Critical Path</th>
              <th scope="col">Min-Max Deviation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{props.proj.pertCP}</td>
              <td>{props.proj.pertDev}%</td>
            </tr>
          </tbody>
        </table>
      </section>

      <section className="newSec white-sec">
        <h2 className="secTitle">PERT Solution</h2>
        <h2 className="result-grade-text">
          {"Your grade: " + props.grade + " out of 20"}
        </h2>
        <div className="big-mt">
          <div className="text-align-left">
            <h5>
              Question 1: PERT Calculations. Round to two decimal places (10
              points out of 20).
            </h5>
          </div>
          <table className="table  mt-2">
            <thead>
              <tr>
                <th scope="col" rowSpan="2">
                  Activity
                </th>
                <th scope="col" colSpan="3">
                  Estimated Durations
                </th>
                <th scope="col" colSpan="2">
                  Distribution
                </th>
              </tr>
              <tr>
                <th scope="col">Min (2/10)</th>
                <th scope="col">Most Likely (1/10)</th>
                <th scope="col">Max (2/10)</th>
                <th scope="col">Mean (1/10)</th>
                <th scope="col">Variance (4/10)</th>
              </tr>
            </thead>
            <tbody>
              {props.params.N.map((job, index) => {
                return (
                  <tr key={("pertResultTR" + job + index).toString()}>
                    <th scope="row">{index + 1 + ". " + job}</th>
                    <ResultTD
                      inputVal={props.inputs.min[index]}
                      keyVal={round2Digits(
                        (1 - props.proj.pertDev / 100) * props.proj.D[index]
                      )}
                      cSpan={1}
                    />
                    <ResultTD
                      inputVal={props.inputs.ml[index]}
                      keyVal={props.proj.D[index]}
                      cSpan={1}
                    />
                    <ResultTD
                      inputVal={props.inputs.max[index]}
                      keyVal={round2Digits(
                        (1 + props.proj.pertDev / 100) * props.proj.D[index]
                      )}
                      cSpan={1}
                    />
                    <ResultTD
                      inputVal={props.inputs.mean[index]}
                      keyVal={props.proj.D[index]}
                      cSpan={1}
                    />
                    <ResultTD
                      inputVal={props.inputs.variance[index]}
                      keyVal={round2Digits(
                        Math.pow(
                          (round2Digits(
                            (1 + props.proj.pertDev / 100) * props.proj.D[index]
                          ) -
                            round2Digits(
                              (1 - props.proj.pertDev / 100) *
                                props.proj.D[index]
                            )) /
                            6,
                          2
                        )
                      )}
                      cSpan={1}
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>

          <table className="table  big-mt text-align-left">
            <tbody>
              <tr>
                <th scope="row">
                  Total Mean (days) of the Critical Path {props.proj.pertCP} (2
                  points out of 20):
                </th>
                <ResultTD
                  inputVal={props.inputs.totalMean}
                  keyVal={props.keys.mean}
                  cSpan={1}
                />
              </tr>
              <tr>
                <th scope="row">
                  Total Variance of the Critical Path {props.proj.pertCP} (3
                  points out of 20):
                </th>
                <ResultTD
                  inputVal={props.inputs.totalVariance}
                  keyVal={props.keys.var}
                />
              </tr>
              <tr>
                <th scope="row">
                  {"The Probability [0-1] of Finishing the Project by " +
                    props.proj.pertDur +
                    " Days (2.5 points out of 20):"}
                </th>
                <ResultTD
                  inputVal={props.inputs.durProb}
                  keyVal={props.keys.durSol}
                  cSpan={1}
                />
              </tr>
              <tr>
                <th scope="row">
                  {"The duration (days) of P" +
                    props.proj.pertPx +
                    " Schedule (2.5 points out of 20):"}
                </th>
                <ResultTD
                  inputVal={props.inputs.pxDur}
                  keyVal={props.keys.pxSol}
                />
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
}

export default PertVis;
