function WarnSubSec(props) {
  return (
    <section className="newSec warn-sec">
      {props.header ? (
        <>
          <p className="">{props.subtitle}</p>
          <h2 className="">{props.title}</h2>
        </>
      ) : (
        <h2 className="">{props.title}</h2>
      )}
      <hr />
    </section>
  );
}

export default WarnSubSec;
