function ResultTD(props) {
  if (props.inputVal === "" || typeof props.inputVal === "undefined") {
    return (
      <td colSpan={props.cSpan}>
        <span className="resultTD-false-cell"> &#10006;&nbsp;</span>
        {props.keyVal}
      </td>
    );
  } else {
    const inputValue = parseFloat(props.inputVal);
    if (
      Number.isNaN(inputValue) &&
      props.inputVal.toString() === props.keyVal.toString()
    ) {
      return (
        <td colSpan={props.cSpan}>
          <span className="resultTD-true-cell">
            &#10004;&nbsp;{props.keyVal}{" "}
          </span>
        </td>
      );
    } else if (inputValue === props.keyVal) {
      return (
        <td colSpan={props.cSpan}>
          <span className="resultTD-true-cell">
            &#10004;&nbsp;{props.keyVal}{" "}
          </span>
        </td>
      );
    } else if (Math.abs(inputValue - props.keyVal) <= 0.011) {
      return (
        <td colSpan={props.cSpan}>
          <span className="resultTD-warning-cell">
            &#10004;&nbsp;{props.inputVal}{" "}
          </span>
          {props.keyVal}
        </td>
      );
    } else {
      return (
        <td colSpan={props.cSpan}>
          <span className="resultTD-false-cell">
            <s>{props.inputVal}</s>&nbsp;
          </span>
          {props.keyVal}
        </td>
      );
    }
  }
}

export default ResultTD;
