function FeatureItem(props) {
  return (
    <div>
      <i
        className={
          "fa-solid fa-3x features-icons features-icn-color " + props.icon
        }
      ></i>
      <br />
      <h3 className="features-head unselectable">{props.title}</h3>
      <p className="feature-text unselectable">{props.text}</p>
    </div>
  );
}

export default FeatureItem;
