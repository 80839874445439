function Q1(props) {
  return (
    <div>
      <div className={"text-align-left " + props.classes}>
        <h5>{"Question " + props.qNr + ": " + props.question}</h5>
      </div>
      <div className="input-group mt-2">
        <input
          type="text"
          name={props.name}
          value={props.value || ""}
          onChange={props.handleFunction}
          className="form-control"
          placeholder={props.placeholder}
        />
      </div>
    </div>
  );
}

export default Q1;
