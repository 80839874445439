function Qmc1(props) {
  return (
    <div>
      <div className={"text-align-left " + props.classes}>
        <h5>{"Question " + props.qNr + ": " + props.question}</h5>
      </div>
      {props.options.map((option, index) => {
        return (
          <div className="input-group mt-2" key={props.keyName + index}>
            <div className="input-group-text">
              <input
                type="radio"
                name={props.name}
                value={index}
                onChange={props.handleFunction}
                className="form-check-input"
              />
            </div>
            <span className="input-group-text">{option}</span>
          </div>
        );
      })}
    </div>
  );
}

export default Qmc1;
