import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./context/userContext";
import "./routes/About/about.css";
import "./routes/Contact/contact.css";
import "./routes/Content/content.css";
import "./routes/ExeCpm/exercise.css";
import "./routes/Home/home.css";
import "./index.css";
import "./routes/UserFullExe/userFullExe.css";
import "./routes/Salim/video.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <UserProvider>
      <App />
    </UserProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
