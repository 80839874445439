function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer id="footer-sec" className="footer-sec">
      <p className="pageDescription unselectable">
        © Copyright Salim Rostami {currentYear}
      </p>
    </footer>
  );
}

export default Footer;
