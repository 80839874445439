import { PageHeader, ResultTD } from "../../components";

function MspVis(props) {
  let tInProjBool = new Array(props.params.rMax * props.params.rMax);
  let rtCount = new Array(props.params.rMax);
  for (let r = 0; r < props.params.rMax; r++) {
    rtCount[r] = 0;
    for (let t = 0; t < props.params.rMax; t++) {
      tInProjBool[r * props.params.rMax + t] = false;
    }
  }
  for (let i = 0; i < props.params.n; i++) {
    if (
      !tInProjBool[props.proj.R[i] * props.params.rMax + props.proj.rType[i]]
    ) {
      tInProjBool[
        props.proj.R[i] * props.params.rMax + props.proj.rType[i]
      ] = true;
      rtCount[props.proj.R[i]]++;
    }
  }
  let rPrintBool = new Array(props.params.rMax);
  for (let r = 0; r < props.params.rMax; r++) {
    rPrintBool[r] = -1;
    for (let t = 0; t < props.params.rMax; t++) {
      if (props.proj.rUsed[r * props.params.rMax + t]) {
        if (rPrintBool[r] === -1) {
          rPrintBool[r] = t;
        }
      }
    }
  }

  return (
    <div>
      <section className="flexSec">
        <PageHeader
          title={"Microsoft Project"}
          description={"Project data for the MSP exercise"}
        />
      </section>
      <section className="newSec ">
        <table className="table textWhite">
          <thead>
            <tr>
              <th scope="col" rowSpan="2">
                #
              </th>
              <th scope="col" rowSpan="2">
                Activity
              </th>
              <th scope="col" rowSpan="2">
                Duration (days)
              </th>
              <th scope="col" rowSpan="2">
                Predecessors
              </th>
              <th scope="col" colSpan="3">
                Resource
              </th>
            </tr>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Type</th>
              <th scope="col">Total Cost</th>
            </tr>
          </thead>
          <tbody>
            {props.params.N.map((job, index) => {
              return (
                <tr key={"mspDataTable" + job + index}>
                  <th scope="row">{index + 1}</th>
                  <td>{job}</td>
                  <td>{props.proj.D[index]}</td>
                  <td>{props.proj.pStr[index]}</td>
                  <td>{props.params.resNames[props.proj.R[index]]}</td>
                  {props.proj.R[index] !== 1 ? (
                    <td>
                      {props.params.resTypeNames[props.proj.rType[index]]}
                    </td>
                  ) : (
                    <td>
                      <b>?</b>
                    </td>
                  )}
                  {props.proj.R[index] === 1 ? (
                    <td>
                      {props.proj.D[index] *
                        props.params.hoursPerDay *
                        props.proj.rCost[
                          props.proj.R[index] * props.params.rMax +
                            props.proj.rType[index]
                        ]}
                    </td>
                  ) : (
                    <td>
                      <b>?</b>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>

        <table className="table textWhite big-mt">
          <thead>
            <tr>
              <th scope="col">Resource</th>
              <th scope="col">Type</th>
              <th scope="col">Cost (per hour)</th>
            </tr>
          </thead>
          <tbody>
            {props.params.resNames.map((resource, r) => {
              return (
                r !== 1 &&
                props.params.resTypeNames.map((type, t) => {
                  return (
                    props.proj.rUsed[r * props.params.rMax + t] && (
                      <tr key={"resTypeTable" + resource + r + type + t}>
                        {rPrintBool[r] === t && (
                          <td
                            className="vertical-align-center"
                            rowSpan={rtCount[r]}
                          >
                            {props.params.resNames[r]}
                          </td>
                        )}
                        <td>{props.params.resTypeNames[t]}</td>
                        <td>{props.proj.rCost[r * props.params.rMax + t]}</td>
                      </tr>
                    )
                  );
                })
              );
            })}
          </tbody>
        </table>
      </section>

      <section className="newSec white-sec">
        <h2 className="secTitle">MSP Solution</h2>
        <h2 className="result-grade-text">
          {"Your grade: " + props.grade + " out of 20"}
        </h2>
        <div className="text-align-left big-mt">
          <h5>Question 1: MSP Calculations (12 points out of 20).</h5>
        </div>
        <table className="table ">
          <thead>
            <tr>
              <th scope="col" rowSpan="2">
                Activity
              </th>
              <th scope="col" rowSpan="2">
                Duration (days)
              </th>
              <th scope="col" rowSpan="2">
                Predecessors
              </th>
              <th scope="col" colSpan="3">
                Resource
              </th>
            </tr>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Type</th>
              <th scope="col">Total Cost</th>
            </tr>
          </thead>
          <tbody>
            {props.params.N.map((job, index) => {
              return (
                <tr key={"mspDataTable" + job + index}>
                  <th scope="row">{index + 1 + ". " + job}</th>
                  <td>{props.proj.D[index]}</td>
                  <td>{props.proj.pStr[index]}</td>
                  <td>{props.params.resNames[props.proj.R[index]]}</td>
                  <td
                    className={
                      props.proj.R[index] === 1 ? "resultTD-warning-cell" : ""
                    }
                  >
                    {props.params.resTypeNames[props.proj.rType[index]]}
                  </td>
                  {props.proj.R[index] === 1 ? (
                    <td>
                      {props.proj.D[index] *
                        props.params.hoursPerDay *
                        props.proj.rCost[
                          props.proj.R[index] * props.params.rMax +
                            props.proj.rType[index]
                        ]}
                    </td>
                  ) : (
                    <ResultTD
                      inputVal={props.inputs.totalCost[index]}
                      keyVal={
                        props.proj.D[index] *
                        props.params.hoursPerDay *
                        props.proj.rCost[
                          props.proj.R[index] * props.params.rMax +
                            props.proj.rType[index]
                        ]
                      }
                      cSpan={1}
                    />
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>

        <table className="table  big-mt">
          <thead>
            <tr>
              <th scope="col">Resource</th>
              <th scope="col">Type</th>
              <th scope="col">Cost per Day (Total Cost / Duration)</th>
            </tr>
          </thead>
          <tbody>
            {props.params.resNames.map((resource, r) => {
              return (
                r === 1 &&
                props.params.resTypeNames.map((type, t) => {
                  return (
                    props.proj.rUsed[r * props.params.rMax + t] && (
                      <tr key={"resTypeTable" + resource + r + type + t}>
                        {rPrintBool[r] === t && (
                          <td
                            className="vertical-align-center"
                            rowSpan={rtCount[r]}
                          >
                            {props.params.resNames[r]}
                          </td>
                        )}
                        <td>{props.params.resTypeNames[t]}</td>
                        <td>
                          {props.params.hoursPerDay *
                            props.proj.rCost[r * props.params.rMax + t]}
                        </td>
                      </tr>
                    )
                  );
                })
              );
            })}
          </tbody>
        </table>

        <table className="table  big-mt">
          <thead>
            <tr>
              <th scope="col">Design Salay Types (4/20)</th>
              <th scope="col">Total Budget (4/20)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <ResultTD
                inputVal={props.inputs.designTypeCount}
                keyVal={rtCount[1]}
                cSpan={1}
              />
              <ResultTD
                inputVal={props.inputs.totalBudget}
                keyVal={props.keys.budget}
                cSpan={1}
              />
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default MspVis;
