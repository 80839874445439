import { useNavigate } from "react-router-dom";
import { useUserContextState, useUserContext } from "../context/userContext";

/**
 *
 * @returns an array, first element is a boolean indicating the login status (true=logged-in)
 * second element is logged-in user data returned from the server (undefined if user is not logged-in)
 */
export const useAuth = () => {
  const uc = useUserContextState();
  if (uc.loggedIn) {
    return [true, uc.data];
  }
  return [false, undefined];
};

/**
 *
 * @returns an array, first element is a boolean indicating the type of logged-in user if any (true=logged-in and is Prof)
 * second element is logged-in user data returned from the server (undefined if user is not logged-in or not a Prof)
 */
export const useProf = () => {
  const uc = useUserContextState();

  if (uc.loggedIn && uc.data.type === "prof") {
    return [true, uc.data];
  } else {
    return [false, undefined];
  }
};

/**
 *
 * @returns an array, first element is a boolean indicating the type of logged-in user if any (true=logged-in and is User)
 * second element is logged-in user data returned from the server (undefined if user is not logged-in or not a User)
 */
export const useUser = () => {
  const uc = useUserContextState();

  if (uc.loggedIn && uc.data.type === "user") {
    return [true, uc.data];
  } else {
    return [false, undefined];
  }
};

export const useLogout = () => {
  const [, setUserContext] = useUserContext();
  const navigate = useNavigate();
  return () => {
    return fetch(process.env.REACT_APP_API_ENDPOINT + "logout", {
      method: "POST",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((r) => {
        if (r.success) {
          setUserContext((oldValues) => ({
            ...oldValues,
            loggedIn: false,
            data: undefined,
          }));
          navigate("/");
        }
      });
  };
};

export const useLogin = (type) => {
  const [, setUserContext] = useUserContext();
  return async (email, password) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}${type}/login`,
      {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email,
          password,
        }),
      }
    );
    if (response.ok) {
      const r = await response.json();
      setUserContext((current) => ({
        ...current,
        loggedIn: true,
        data: r.data,
      }));
    } else if (response.status === 401) {
      throw Error("Email or password is not correct");
    } else {
      throw Error("Something went wrong");
    }
  };
};
