import { createContext, useState, useContext } from "react";

const UserContext = createContext([{}, () => {}]);

// Custom hook, returns array with state, setter
export const useUserContext = () => useContext(UserContext);
export const useUserContextState = () => useContext(UserContext)[0];

const UserProvider = ({ children }) => {
  const state = useState(undefined);

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
