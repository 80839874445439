import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { useUserContext } from "./context/userContext";
import {
  Layout,
  ContentLayout,
  RequireProf,
  RequireUser,
  FullscreenLoading,
} from "./components";
import {
  Home,
  User,
  Cpm,
  Rl,
  Pert,
  Msp,
  Groups,
  GroupAdd,
  GroupEdit,
  GroupUsers,
  GroupAddUsers,
  NoMatch,
  Unauthorized,
  Login,
  UserSetPassword,
  UserForgotPassword,
  DetailedUser,
  Salim,
  Content,
  Contact,
  About,
} from "./routes";
import { Leaderboard } from "./routes/Leaderboard";

function App() {
  const [userContext, setUserContext] = useUserContext();

  useEffect(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "whoami", {
      credentials: "include",
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("user is not valid");
        }
        return response.json();
      })
      .then((r) => {
        setUserContext({
          loggedIn: true,
          data: r.data,
        });
      })
      .catch((error) => {
        setUserContext({
          loggedIn: false,
          data: undefined,
        });
      });
  }, [setUserContext]);

  if (userContext) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="salim" element={<Salim />} />
            <Route path="leaderboard" element={<Leaderboard />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="prof">
              <Route
                index
                element={
                  <RequireProf>
                    <Groups />
                  </RequireProf>
                }
              />
              <Route
                path="addgroup"
                element={
                  <RequireProf>
                    <GroupAdd />
                  </RequireProf>
                }
              />
              <Route
                path="editgroup/:id"
                element={
                  <RequireProf>
                    <GroupEdit />
                  </RequireProf>
                }
              />
              <Route
                path="groupusers/:id"
                element={
                  <RequireProf>
                    <GroupUsers />
                  </RequireProf>
                }
              />
              <Route
                path="addusers/:id"
                element={
                  <RequireProf>
                    <GroupAddUsers />
                  </RequireProf>
                }
              />
              <Route
                path="detaileduser/:id"
                element={
                  <RequireProf>
                    <DetailedUser />
                  </RequireProf>
                }
              />
              <Route path="login" element={<Login type="prof" />} />
            </Route>
            <Route path="user">
              <Route
                index
                element={
                  <RequireUser>
                    <User />
                  </RequireUser>
                }
              />
              <Route path="login" element={<Login type="user" />} />
              <Route path="forgotpassword" element={<UserForgotPassword />} />
              <Route
                path="compeletesignup"
                element={<UserSetPassword type={"compeletesignup"} />}
              />
              <Route
                path="resetpassword"
                element={<UserSetPassword type={"resetpassword"} />}
              />
            </Route>
            <Route
              path="content"
              element={
                <RequireUser>
                  <ContentLayout />
                </RequireUser>
              }
            >
              <Route
                index
                element={
                  <RequireUser>
                    <Content />
                  </RequireUser>
                }
              />
              <Route
                path="cpm"
                element={
                  <RequireUser>
                    <Cpm />
                  </RequireUser>
                }
              />
              <Route
                path="rl"
                element={
                  <RequireUser>
                    <Rl />
                  </RequireUser>
                }
              />
              <Route
                path="pert"
                element={
                  <RequireUser>
                    <Pert />
                  </RequireUser>
                }
              />
              <Route
                path="msp"
                element={
                  <RequireUser>
                    <Msp />
                  </RequireUser>
                }
              />
            </Route>
            <Route path="unauthorized" element={<Unauthorized />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  } else {
    return <FullscreenLoading />;
  }
}

export default App;
