import { PageHeader, ResultTD, Timetable } from "../../components";

function RlVis(props) {
  function resultTablePreProcess(timeArr, stArr, D, R, makespan, n, rMax) {
    for (let r = 0; r < rMax; r++) {
      timeArr[r] = new Array(makespan + 1).fill(-2);
      for (let t = 0; t < makespan + 1; t++) {
        let schd = false;
        for (var i = 0; i < n; i++) {
          if (R[i] === r && stArr[i] === t) {
            timeArr[r][t] = i;
            schd = true;
            t = t + D[i] - 1;
            break;
          }
        }
        if (!schd) {
          timeArr[r][t] = -1;
        }
      }
    }
  }
  let sgsTimes = new Array(props.params.rMax);
  let pgsTimes = new Array(props.params.rMax);

  resultTablePreProcess(
    sgsTimes,
    props.keys.sgsSt[props.proj.prRuleNr],
    props.proj.D,
    props.proj.R,
    Math.max(
      props.keys.sgsMakespan[props.proj.prRuleNr],
      props.keys.pgsMakespan[props.proj.prRuleNr]
    ),
    props.params.n,
    props.params.rMax
  );
  resultTablePreProcess(
    pgsTimes,
    props.keys.pgsSt[props.proj.prRuleNr],
    props.proj.D,
    props.proj.R,
    Math.max(
      props.keys.sgsMakespan[props.proj.prRuleNr],
      props.keys.pgsMakespan[props.proj.prRuleNr]
    ),
    props.params.n,
    props.params.rMax
  );

  return (
    <div>
      <section className="flexSec">
        <PageHeader
          title={"Resource Leveling"}
          description={"Project data for the RL exercise"}
        />
      </section>
      <section className="newSec ">
        <table className="table textWhite">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Activity</th>
              <th scope="col">Duration</th>
              <th scope="col">Predecessors</th>
              <th scope="col">Resource</th>
            </tr>
          </thead>
          <tbody>
            {props.params.N.map((job, index) => {
              return (
                <tr key={("cpmDataTable" + job + index).toString()}>
                  <th scope="row">{index + 1}</th>
                  <td>{job}</td>
                  <td>{props.proj.D[index]}</td>
                  <td>{props.proj.pStr[index]}</td>
                  <td>{props.params.rNames[props.proj.R[index]]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <table className="table textWhite big-mt">
          <thead>
            <tr>
              <th scope="col">Assigned Priority Rule:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                {props.params.prAbr[props.proj.prRuleNr] +
                  ": " +
                  props.params.prNames[props.proj.prRuleNr]}
              </th>
            </tr>
            <tr>
              <td>Tiebreaker* rule: Lowest Activity Index (#) First</td>
            </tr>
          </tbody>
        </table>
      </section>

      <section className="newSec white-sec">
        <h2 className="secTitle">
          RL Solution: {props.params.prAbr[props.proj.prRuleNr]}
        </h2>
        <h2 className="result-grade-text">
          {"Your grade: " + props.grade + " out of 20"}
        </h2>
        <table className="table big-mt">
          <thead>
            <tr>
              <th scope="col">Activity</th>
              <th scope="col">Priority Level (6/18)</th>
              <th scope="col">SGS Start Time (6/18)</th>
              <th scope="col">PGS Start Time (6/18)</th>
            </tr>
          </thead>
          <tbody>
            {props.params.N.map((job, index) => {
              return (
                <tr key={("cpmSolTable" + job + index).toString()}>
                  <th scope="row">{job}</th>
                  <ResultTD
                    inputVal={props.inputs.prLvl[index]}
                    keyVal={
                      props.keys.priority[props.proj.prRuleNr].indexOf(index) +
                      1
                    }
                    cSpan={1}
                  />
                  <ResultTD
                    inputVal={props.inputs.sgsSt[index]}
                    keyVal={props.keys.sgsSt[props.proj.prRuleNr][index]}
                    cSpan={1}
                  />
                  <ResultTD
                    inputVal={props.inputs.pgsSt[index]}
                    keyVal={props.keys.pgsSt[props.proj.prRuleNr][index]}
                    cSpan={1}
                  />
                </tr>
              );
            })}
          </tbody>
        </table>

        <table className="table  big-mt">
          <thead>
            <tr>
              <th scope="col">Priority Level</th>
              <th scope="col">Activity</th>
            </tr>
          </thead>
          <tbody>
            {props.params.N.map((job, index) => {
              return (
                <tr key={("cpmSolTable" + job + index).toString()}>
                  <th scope="row">{index + 1}</th>
                  <td>
                    {
                      props.params.N[
                        props.keys.priority[props.proj.prRuleNr][index]
                      ]
                    }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <table className="table  big-mt">
          <thead>
            <tr>
              <th scope="col">Priority Rule</th>
              <th scope="col">SGS Duration (1/20)</th>
              <th scope="col">PGS Duration (1/20)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">{props.params.prAbr[props.proj.prRuleNr]}</th>
              <ResultTD
                inputVal={props.inputs.sgsMakespan}
                keyVal={props.keys.sgsMakespan[props.proj.prRuleNr]}
                cSpan={1}
              />
              <ResultTD
                inputVal={props.inputs.pgsMakespan}
                keyVal={props.keys.pgsMakespan[props.proj.prRuleNr]}
                cSpan={1}
              />
            </tr>
          </tbody>
        </table>
      </section>

      <div className="white-sec RL-timetable-div">
        <Timetable
          schemeName="Serial Schedule Generation"
          makespan={props.keys.sgsMakespan}
          params={props.params}
          proj={props.proj}
          timeArr={sgsTimes}
          className=""
        />
        <Timetable
          schemeName="Parallel Schedule Generation"
          makespan={props.keys.sgsMakespan}
          params={props.params}
          proj={props.proj}
          timeArr={pgsTimes}
          className="RL-timetable-mt"
        />
      </div>

      <section className="newSec colorSecondary-sec">
        <h2 className="secTitle">Other Priority Rules</h2>
        <table className="table big-mt">
          <thead>
            <tr>
              <th scope="col" className="text-align-left">
                Priority Rule
              </th>
              <th scope="col">Priority List</th>
              <th scope="col">SGS Start Times</th>
              <th scope="col">SGS Duration</th>
              <th scope="col">PGS Start Times</th>
              <th scope="col">PGS Duration</th>
            </tr>
          </thead>
          <tbody>
            {props.params.prAbr.map((abr, index) => {
              return (
                <tr key={"allPrs" + abr + index}>
                  <th scope="row" className="text-align-left">
                    {props.params.prAbr[index] +
                      ": " +
                      props.params.prNames[index]}
                  </th>
                  <td>
                    {props.keys.priority[index].map((item, index) => {
                      return (
                        <span key={"pr" + item + "-" + index}>
                          {(index > 0 ? "-" : "") + props.params.N[item]}
                        </span>
                      );
                    })}
                  </td>
                  <td>
                    {props.keys.sgsSt[index].map((st, index) => {
                      return (
                        <span key={"sgsSt" + st + "-" + index}>
                          {(index > 0 ? "-" : "[") +
                            st +
                            (index === props.params.n - 1 ? "]" : "")}
                        </span>
                      );
                    })}
                  </td>
                  <td>{props.keys.sgsMakespan[index]}</td>
                  <td>
                    {props.keys.pgsSt[index].map((st, index) => {
                      return (
                        <span key={"pgsSt" + st + "-" + index}>
                          {(index > 0 ? "-" : "[") +
                            st +
                            (index === props.params.n - 1 ? "]" : "")}
                        </span>
                      );
                    })}
                  </td>
                  <td>{props.keys.pgsMakespan[index]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default RlVis;
