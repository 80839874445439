import { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  Header,
  Footer,
  TRProf,
  TRProfMC,
  SubmitModal,
  FullscreenLoading,
  PageHeader,
} from "../../components";

function GroupEdit() {
  const navigate = useNavigate();

  const { id } = useParams();

  const [inputs, setInputs] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "prof/group/" + id, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((r) => {
        if (r.success) {
          setInputs(r.data);
        } else {
          console.log(r.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const arrayChange = (event) => {
    const target = event.target;
    const [name, index] = event.target.name.split("-");
    const value = target.type === "checkbox" ? target.checked : target.value;
    setInputs((values) => {
      const prop = values[name];
      prop[index] = value;
      return { ...values, [name]: prop };
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(process.env.REACT_APP_API_ENDPOINT + "prof/group/" + id, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(inputs),
    })
      .then((response) => response.json())
      .then((r) => {
        if (r.success) {
          navigate("/prof", { state: { groups: r.data } });
        }
      });
  };

  const [deleteState, setDeleteState] = useState(false);

  const handleDelete = (event) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "prof/group/" + id, {
      method: "DELETE",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => setDeleteState(data.success));

    event.preventDefault();

    if (deleteState) {
      alert("The group is successfully Deleted!");
    }

    navigate("/prof");
  };

  if (inputs != null) {
    return (
      <div>
        <Header />
        <section className="flexSec">
          <PageHeader
            title={inputs.name}
            description={"Edit group — activate/deactivate exercises"}
          />
        </section>
        <section className="newSec ">
          <form
            className="display-inline mt-5"
            onSubmit={handleSubmit}
            onKeyDown={(e) =>
              (e.key === "Enter" ||
                e.key === "ArrowUp" ||
                e.key === "ArrowDown") &&
              e.preventDefault()
            }
            onWheel={(e) => e.target.blur()}
          >
            <table className="table textWhite">
              <tbody>
                <TRProfMC
                  title="Active Exercises (Checked)"
                  keyName="exerActiveQ"
                  options={["CPM", "RL", "PERT", "MSP"]}
                  name="exerActive"
                  checked={inputs.exerActive}
                  handleFunction={arrayChange}
                />
                <TRProf
                  title="Program (Text: e.g. MIB)"
                  type="text"
                  name="program"
                  value={inputs.program}
                  handleChange={handleChange}
                  placeholder="Program"
                  required={true}
                />
                <TRProf
                  title="Academic Year (Number: e.g. 2223)"
                  type="number"
                  name="year"
                  value={inputs.year}
                  handleChange={handleChange}
                  placeholder="Year"
                  required={true}
                />
                <TRProf
                  title="Semester (Number: 1 or 2)"
                  type="number"
                  name="semester"
                  value={inputs.semester}
                  handleChange={handleChange}
                  placeholder="Semester"
                  required={true}
                />
                <TRProf
                  title="Campus (L or P)"
                  type="text"
                  name="campus"
                  value={inputs.campus}
                  handleChange={handleChange}
                  placeholder="Campus"
                  required={true}
                />
                <TRProf
                  title="Index (Optional)"
                  type="number"
                  name="index"
                  value={inputs.index}
                  handleChange={handleChange}
                  placeholder="Index"
                  required={false}
                />
                <tr>
                  <th scope="row">{"Group Name (Readonly):"}</th>
                  <td>
                    <input
                      value={
                        (typeof inputs.program !== "undefined" &&
                        inputs.program !== ""
                          ? inputs.program
                          : "") +
                        (typeof inputs.year !== "undefined" &&
                        inputs.year !== ""
                          ? "-" + inputs.year
                          : "") +
                        (typeof inputs.semester !== "undefined" &&
                        inputs.semester !== ""
                          ? "-S" + inputs.semester
                          : "") +
                        (typeof inputs.campus !== "undefined" &&
                        inputs.campus !== ""
                          ? "-" + inputs.campus
                          : "") +
                        (typeof inputs.index !== "undefined" &&
                        inputs.index !== ""
                          ? "-" + inputs.index
                          : "")
                      }
                      className="form-control"
                      placeholder="Name"
                      readOnly={true}
                    />
                  </td>
                </tr>
                <TRProf
                  title="CPM Points (Out of 20)"
                  type="number"
                  name="exerPoints-0"
                  value={inputs.exerPoints[0]}
                  handleChange={arrayChange}
                  placeholder="CPM Points"
                  required={true}
                />
                <TRProf
                  title="RL Points (Out of 20)"
                  type="number"
                  name="exerPoints-1"
                  value={inputs.exerPoints[1]}
                  handleChange={arrayChange}
                  placeholder="RL Points"
                  required={true}
                />
                <TRProf
                  title="PERT Points (Out of 20)"
                  type="number"
                  name="exerPoints-2"
                  value={inputs.exerPoints[2]}
                  handleChange={arrayChange}
                  placeholder="PERT Points"
                  required={true}
                />
                <TRProf
                  title="MSP Points (Out of 20)"
                  type="number"
                  name="exerPoints-3"
                  value={inputs.exerPoints[3]}
                  handleChange={arrayChange}
                  placeholder="MSP Points"
                  required={true}
                />
              </tbody>
            </table>

            <Link
              to="/prof"
              className="btn btn-lg btn-outline-light  mt-5 mx-5"
            >
              Cancel
            </Link>

            <input
              className="btn btn-lg btn-outline-success mt-5"
              type="submit"
              value="Save"
            />
          </form>
          <form
            className="display-inline mx-5"
            onSubmit={handleDelete}
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          >
            <SubmitModal
              btnText="Delete"
              classes="mt-5"
              variant="outline-danger"
              title="Are you sure?"
              details="Deleting a group deletes all its students. This action is irreversible."
              handleSubmit={handleDelete}
            />
          </form>
        </section>
        <Footer />
      </div>
    );
  } else {
    return <FullscreenLoading />;
  }
}

export default GroupEdit;
