function ProjecButton({ links, customClasses, newTab }) {
  return (
    <div className="mb-2">
      {typeof links !== "undefined" &&
        links.map((link, index) => {
          return (
            <a
              className={"btn btnWide btnSmall " + customClasses}
              href={link.lUrl}
              target={newTab}
              key={"link" + index + link.lName}
            >
              {link.lName}
            </a>
          );
        })}
    </div>
  );
}

export default ProjecButton;
