function Timetable(props) {
  return (
    <div className={props.class}>
      <table className={"RL-timetable noHover"}>
        <thead>
          <tr>
            <th scope="col" className="RL-timetable-top-row">
              Resource
            </th>
            <th scope="col" colSpan={props.makespan + 1}>
              {props.params.prAbr[props.proj.prRuleNr]} Priority Rule:{" "}
              {props.schemeName}
            </th>
          </tr>
        </thead>
        <tbody>
          {props.params.rNames.map((rName, r) => {
            return (
              <tr key={"ResourceRow" + rName + r}>
                <th
                  scope="row"
                  className="RL-timetable-left-col"
                  style={{ backgroundColor: props.params.rColors[r] }}
                >
                  {props.params.rNames[r]}
                </th>
                {props.timeArr[r].map((j, t) => {
                  return j > -1 ? (
                    <td
                      key={"jobCell" + j + t}
                      colSpan={props.proj.D[j]}
                      className="RL-timetable-cell"
                      style={{ backgroundColor: props.params.rColors[r] }}
                    >
                      {props.params.N[j]}
                    </td>
                  ) : (
                    j === -1 && (
                      <td
                        key={"idleCell" + j + t}
                        className="RL-timetable-cell"
                      >
                        {" "}
                      </td>
                    )
                  );
                })}
              </tr>
            );
          })}
          <tr>
            <th></th>
            {props.timeArr[0].map((j, t) => {
              return (
                <td className={"text-align-left"} key={"timebar" + t}>
                  {t}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Timetable;
