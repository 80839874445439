import projectData from "./leaderboardData.json";
import { Footer, Header, PageHeader, Project } from "../../components";

const Leaderboard = () => {
  const ProjectList = () =>
    projectData.map((project, i) => (
      <Project
        key={i}
        oddEven={projectData.length % 2 === 0 ? 1 : 0}
        id={project.id}
        title={project.title}
        top={project.top}
        bottom={project.bottom}
        image={project.image}
        color={project.bgcolor}
        github={project.github}
        deployed={project.deployed}
        description={project.description}
      />
    ));

  return (
    <div>
      <Header />
      <section id="content-sec" className="projects">
        <PageHeader title="Leaderboard" description="Meet our top students" />
        <div>
          <div className="row">
            <ProjectList />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Leaderboard;
