import { Link } from "react-router-dom";
import { Header, Footer, PageHeader } from "..";

function ExerNotActive(props) {
  return (
    <div>
      <Header />
      <section className="flexSec">
        <PageHeader
          title={props.exerName + " Exercise Inactive"}
          description={"If this is unexpected, contact your teacher by email"}
        />
      </section>
      <section className="newSec">
        <Link to={"/content"} className={"btn btn-lg btn-outline-light"}>
          Back to Course Content
        </Link>
      </section>
      <Footer />
    </div>
  );
}

export default ExerNotActive;
